import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SingleCard from '../components/SingleCard';
import initialProjects from '../initialProjects';
import Header from '../components/Header';
import { useParams } from 'react-router-dom';


export default function ProjectScreen() {
  const { id } = useParams();
  const [isToggled, setIsToggled] = useState(true);

 
  const [allProducts, setAllProducts] = useState(() => {
    // Find the category with the given id and return its productCategory
    const category = initialProjects.products.find(
      (product) => product.categoryId === id
    );

    return category ? category.productCategory : [];
  });
  const toggleCat = () => setIsToggled(true);
  const toggleList = () => setIsToggled(false);

  useEffect(() => {
    const category = initialProjects.products.find(
      (product) => product.categoryId === id
    );

    setAllProducts(category ? category.productCategory : []);
  }, [id]);



 
  if (!allProducts.length) {
    return (
      <>
        <Header />
        <div className="container-fluid product-section">
          <Row className="category_noname"></Row>
          <Row className="product-page-non w-100 p-5">
            <h2 className="h2-title">هیچ جۆرە ساردەمەنیەک نەدۆزرایەوە</h2>
          </Row>
        </div>
      </>
    );
  }

  const categoryInfo = initialProjects.products.find(
    (product) => product.categoryId === id
  );
  // console.log(categoryInfo);
  return (
    <>
      <Header />
      <div className="container-fluid product-section overflow-auto">
        <div className="category_name">
          <h1 className="category-title">{categoryInfo.categoryName}</h1>
          <div className="categoryList">
            <i
              onClick={toggleList}
              className="iconList fa fa-list-ul"
              aria-hidden="true"
            ></i>
            <i
              onClick={toggleCat}
              className="iconList fa fa-th"
              aria-hidden="true"
            ></i>
          </div>
        </div>

        <div className="container-fluid mt-2 w-100">
          {isToggled ? (
            <Row>
              {allProducts.map((product, index) => (
                <div key={index} className="col-6 col-md-4 col-lg-3 p-2">
                  <SingleCard
                    data={product}
                    category={categoryInfo}
                    isToggled={isToggled}
                  />
                </div>
              ))}
            </Row>
          ) : (
            <Row>
              {allProducts.map((product, index) => (
                <Col key={index} className="col-12 col-md-4 col-lg-3 p-2">
                  <SingleCard
                    data={product}
                    category={categoryInfo}
                    isToggled={isToggled}
                  />
                </Col>
              ))}
            </Row>
          )}
        </div>
      </div>
    </>
  );
}
