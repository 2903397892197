import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomeScreen from './screens/HomeScreen';
import ProductScreen from './screens/ProductScreen';
import Container from 'react-bootstrap/Container';
import Footer from './components/Footer';
import ProjectScreen from './screens/ProjectScreen';

function App() {
  return (
    <BrowserRouter>
      <div className="site-container">
        {/* <NavbarHeader /> */}
        <main>
          <Container fluid="true">
            <Routes>
              <Route
                exact
                path="/category/:param1/:param2"
                element={<ProductScreen />}
              ></Route>
              <Route
                exact
                path="/category/:id"
                element={<ProjectScreen />}
              ></Route>
              {/* <Route exact path="/aboutus" element={<AboutUs />}></Route>
              <Route exact path="/contactus" element={<ContactUs />}></Route> */}
              <Route path="/" element={<HomeScreen />} exact></Route>
            </Routes>
          </Container>
          {/* <Routes>
            <Route path="/" element={<HomeScreen />} exact></Route>
          </Routes> */}
        </main>
        <footer>
          <Footer />
        </footer>
        {/* <footer>
          <div>
            
            <div>All right reserved</div>{' '}
          </div>
        </footer> */}
      </div>
    </BrowserRouter>
  );
}
export default App;
