import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import initialProjects from '../initialProjects';
// import Header from '../components/Header';

export default function ProductScreen(props) {
  const { t } = useTranslation();
  const { param1, param2 } = useParams();
  const navigate = useNavigate();

  const product = initialProjects.products
    .find((item) => item.categoryId === param1)
    ?.productCategory.find((prod) => prod._id === param2);

  return (
    <>
      {/* <Header /> */}
      <div className="position-relative">
        <div className="content-product">
          <i
            onClick={() => navigate(-1)}
            className="arrow-header position-absolute top-0 start-0 fa fa-arrow-circle-left"
          ></i>
          <Link className="text-color" to={`/`}>
            <i
              className="title-header position-absolute top-0 end-0 fa fa-home"
              aria-hidden="true"
            ></i>
          </Link>
        </div>

        <div className="image-group">
          <img
            className="d-flex w-100 bg-image"
            src={product.project_img}
            alt={product.projectName}
          ></img>
        </div>

        <div className="product-name">
          <div className="product-details">
            <h3 className="product-title">{t(`${product.kuName}`)}</h3>
            <h3 className="product-title">{t(`${product.price}`)}</h3>
          </div>
          <div className="product-desc p-2">
            <h3 className="desc-product p-2">Descriprtion</h3>
            <p className="product-title p-2">{t(`${product.description}`)}</p>
          </div>
        </div>
      </div>
    </>
  );
}
