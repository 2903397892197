import React from 'react';
import { Link } from 'react-router-dom';

const SingleCard = ({ data, category, isToggled }) => {
  // Context provider

  return (
    <>
      {isToggled ? (
        <Link
          className="text-color"
          to={`/category/${category.categoryId}/${data._id}`}
          // to={{
          //   pathname: `/category/${category.categoryId}/product/${data._id}`,
          //   state: { categoryName: category.categoryName },
          // }}

          // to={{
          //   pathname: `/product/${category.categoryId}`,
          //   state: { data: data }
          // }}
        >
          <div className="card ">
            <img src={data.project_img} className="card-img-top" alt="..." />
            <div className="card-body">
              <div className="text-section">
                <p className="card-title fw-bold">{data.ArName}</p>
                <p className="card-text">{data.kuName}</p>
                <div className="cta-section">
                  <div className="price-title ">
                    {data.dinar} {data.price}
                    {/* <div className=" ">{data.dinar}</div> */}
                  </div>

                  {/* <a href="#" class="btn btn-light">Buy Now</a> */}
                </div>
              </div>
            </div>
          </div>
        </Link>
      ) : (
        <Link
          className="text-color"
          to={`/product/${data._id}`}
          // to={{
          //   pathname: `/product/${data._id}`,
          //   state: { product: data },
          // }}
          // onClick={scrollToTop}
        >
          <div className="cardList ">
            <img src={data.project_img} className="card-img-top" alt="..." />
            <div className="cardList-body d-flex align-items-start">
              <div className="textList-section ">
                <div className="title-section ">
                  <p className="cardList-text">{data.kuName}</p>
                  <p className="cardList-title fw-bold">{data.ArName}</p>
                </div>

                <div className="ctaList-section">
                  <div className="priceList-title ">
                    {data.dinar} {data.price}
                  </div>

                  {/* <a href="#" class="btn btn-light">Buy Now</a> */}
                </div>
              </div>
            </div>
          </div>
        </Link>
      )}
    </>
  );
};
export default SingleCard;
