const initialProjects = {
  products: [
    {
      categoryId: '1',
      categoryName: 'Brakfast',
      // kuName: ' ',
      bgcolor: '#E7708C',
      color: '#000000',
      productCategory: [
        {
          _id: '1',
          // ArName: 'دۆندرمەی سادە',
          kuName: 'Breakfast',
          price: '16,000',
          dinar: 'IQD',
          project_img: '/images/Breakfast/b1.png',
          description:
            'Loren loren loren Loren loren loren Loren loren loren Loren loren loren Loren loren loren',
        },
        {
          _id: '2',
          // ArName: 'دۆندرمەی سادە',
          kuName: 'Breakfast For 4 Person',
          price: '16,000',
          dinar: 'IQD',
          project_img: '/images/Breakfast/b2.png',
          description:
            'Loren loren loren Loren loren loren Loren loren loren Loren loren loren Loren loren loren',
        },
        {
          _id: '3',
          // ArName: 'دۆندرمەی سادە',
          kuName: 'Breakfast',
          price: '16,000',
          dinar: 'IQD',
          project_img: '/images/Breakfast/b3.png',
          description:
            'Loren loren loren Loren loren loren Loren loren loren Loren loren loren Loren loren loren',
        },
        {
          _id: '4',
          // ArName: 'دۆندرمەی سادە',
          kuName: 'Breakfast',
          price: '16,000',
          dinar: 'IQD',
          project_img: '/images/Breakfast/b4.png',
          description:
            'Loren loren loren Loren loren loren Loren loren loren Loren loren loren Loren loren loren',
        },
        {
          _id: '5',
          // ArName: 'دۆندرمەی سادە',
          kuName: 'Breakfast',
          price: '16,000',
          dinar: 'IQD',
          project_img: '/images/Breakfast/b3.png',
          description:
            'Loren loren loren Loren loren lorenLoren loren loren Loren loren loren Loren loren loren',
        },
        {
          _id: '6',
          // ArName: 'دۆندرمەی سادە',
          kuName: 'Breakfast',
          price: '16,000',
          dinar: 'IQD',
          project_img: '/images/Breakfast/b4.png',
          description: 'Loren loren loren',
        },
      ],
    },
    {
      categoryId: '2',
      categoryName: 'Lunch',
      // kuName: 'ئەزبەری میوە',
      bgcolor: '#D21D5A',
      color: '#fff',
      productCategory: [
        {
          _id: '1',
          // ArName: 'ازبری فراولـة',
          kuName: 'Burger',
          price: '10,000',
          dinar: 'IQD',
          project_img: '/images/lunch/l1.png',
        },
        {
          _id: '2',
          // ArName: 'ازبری کۆکتێل',
          kuName: 'Rice with Meat',
          price: '12,000',
          dinar: 'IQD',
          project_img: '/images/lunch/l2.png',
        },
        {
          _id: '3',
          // ArName: 'ازبری رمان',
          kuName: 'Bryani',
          price: '15,000',
          dinar: 'IQD',
          project_img: '/images/lunch/l3.png',
        },
        {
          _id: '4',
          // ArName: 'ازبری حلیب موز',
          kuName: 'Chicken',
          price: '14,00',
          dinar: 'IQD',
          project_img: '/images/lunch/l4.png',
        },
      ],
    },
    {
      categoryId: '3',
      categoryName: 'Dinnar',
      // kuName: 'نوتێلا',
      bgcolor: '#532A25',
      color: '#fff',
      productCategory: [
        {
          _id: '1',
          // ArName: 'ایس کریم نوتیللا و لوتوس',
          kuName: 'Chicken Steak',
          price: '11,000',
          dinar: 'IQD',
          project_img: '/images/dinnar/d1.png',
        },
        {
          _id: '2',
          // ArName: 'ایس کریم نوتیللا و  فروتي',
          kuName: 'Chicken',
          price: '8,000',
          dinar: 'IQD',
          project_img: '/images/dinnar/d2.png',
        },
        {
          _id: '3',
          // ArName: 'ایس کریم نوتیللا و  جرزات',
          kuName: 'Meat',
          price: '18,000',
          dinar: 'IQD',
          project_img: '/images/dinnar/d3.png',
        },
        {
          _id: '4',
          // ArName: 'ایس کریم نوتیللا و  کیندر',
          kuName: 'Meat Steak',
          price: '10,000',
          dinar: 'IQD',
          project_img: '/images/dinnar/d4.png',
        },
      ],
    },

    {
      categoryId: '4',
      categoryName: 'Drink',
      // kuName: 'شەربەتی سروشتی',
      bgcolor: '#F9D43B',
      color: '#000000',
      productCategory: [
        {
          _id: '1',
          // ArName: '',
          kuName: 'Mirinda',
          price: '2,000',
          dinar: 'IQD',
          project_img: '/images/drink/dr1.png',
        },
        {
          _id: '2',
          // ArName: '',
          kuName: 'Sprite',
          price: '2,000',
          dinar: 'IQD',
          project_img: '/images/drink/dr2.png',
        },
        {
          _id: '3',
          // ArName: 'عـصیـر کـوکـتیل زبیـب',
          kuName: 'CocaCola',
          price: '2,000',
          dinar: 'IQD',
          project_img: '/images/drink/dr3.png',
        },
        {
          _id: '4',
          // ArName: 'عـصیـر طبیعی گیلاس',
          kuName: 'Pepsi',
          price: '2,000',
          dinar: 'IQD',
          project_img: '/images/drink/dr4.png',
        },
      ],
    },
  ],
};

export default initialProjects;
