import React from 'react';
import { useTranslation } from 'react-i18next';
import Work from './Work';
import { Link } from 'react-router-dom';
import BackButton from './BackButton';
import { useNavigate } from 'react-router-dom';

export default function Header() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const headerImg = '../images/banner.png';
  const logo = '../images/logo.png';
  return (
    <>
      <div className="imgHeader position-relative sticky">
        <div className="content">
          <i
            onClick={() => navigate(-1)}
            className="arrow-header position-absolute top-0 start-0 fa fa-arrow-circle-left"
          ></i>
          <Link
            className="text-color"
            to={`/`}

            // onClick={scrollToTop}
          >
            <i
              className="title-header position-absolute top-0 end-0 fa fa-home"
              aria-hidden="true"
            ></i>
            <div className="header-logo d-flex justify-content-center justify-self-center">
              <img
                className="img-logo"
                src={logo}
                alt={logo}
              ></img>
            </div>
          </Link>
        </div>
        <img
          className="d-flex w-100 bg-image"
          src={headerImg}
          alt={headerImg}
        ></img>
      </div>
      <Work />
    </>

    // <div className=" shipping-info ">
    //   <div className=" arrow-div ">
    //     <i className="arrow-header fa fa-arrow-circle-left"></i>
    //     <Link
    //       className="text-color"
    //       to={`/`}

    //       // onClick={scrollToTop}
    //     >
    //       <i className="title-header fa fa-home" aria-hidden="true"></i>
    //       {/* <div className="title-header "><fa fa-bar>Menu</fa></div> */}
    //     </Link>
    //   </div>
    //   <div className="img-logo">
    //     <img className="bg-image" src={headerImg} alt={headerImg}></img>
    //   </div>
    //   <Work />
    // </div>
  );
}
