const data = {
  products: [
    {
      _id: '1',
      name: 'Breakfast',
      // kuName: 'ئایس کرێم',
      image: '/images/ice-cream/icecream.png',
      // imageBranchf: ['Business Website', 'Personal Website', 'Ecommerce Store'],
      // description: 'Ice Cream',
    },
    {
      _id: '2',
      name: 'Lunch',
      // kuName: 'ئەزبەری میوە',
      image: '/images/splash/splash.jpg',
      // description: 'Fruit Splashe',
    },
    {
      _id: '3',
      name: 'Dinnar',
      // kuName: 'نوتێلا',
      image: '/images/nutella/nutella.png',
      // description: 'Nutella',
    },
    {
      _id: '4',
      name: 'Drinking',
      // kuName: 'شەربەتی سروشتی',
      image: '/images/juice/fjuice.png',
      // description: 'Fresh Juice',
    },
  ],
};
export default data;
