import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function Product(props) {
  const { t } = useTranslation();
  const { product } = props;

  return (
    <div key={product._id} className="box  w-100 ">
      <Link className="text-color" to={`/category/${product._id}`}>
        {/* <img
          className="img-section"
          src={product.image}
          alt={product.projectName}
        /> */}
        <div className="service-name">{t(`${product.name}`)}</div>
        {/* <div className="service-name">{t(`${product.kuName}`)}</div> */}
      </Link>
    </div>
  );
}
