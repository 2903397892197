import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
// import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
// import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
//import InstagramIcon from '@mui/icons-material/Instagram';
//import TwitterIcon from '@mui/icons-material/Twitter';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaFacebook, FaInstagramSquare } from 'react-icons/fa';
//import  from '@mui/icons-material/Facebook';
import { FaArrowCircleUp } from 'react-icons/fa';

export default function Footer() {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 600) {
      setVisible(true);
    } else if (scrolled <= 600) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  window.addEventListener('scroll', toggleVisible);
  

  return (
    <>
            {/* <div className="scroll-button">
        <FaArrowCircleUp
          onClick={scrollToTop}
          style={{ display: visible ? 'inline' : 'none' }}
        />
      </div> */}
      {/* <div className="row-footer"> */}
        <div className="row-line  text-center">
          <div className="footer-copy w-100">
            Restaurant Menu
          </div>
        </div>
      {/* </div> */}
    </>
  );
}
